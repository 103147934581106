import { Element } from 'react-scroll';
import "./PagesStyles.scss";
import CONSTANTS from '../Constants';
import { isSafari } from "react-device-detect";
import AnalyticsHelper from '../components/helpers/AnalyticsHelper';
import { Trans, useTranslation } from 'react-i18next';


export default function Quality() {
    const qualityEventTracker = AnalyticsHelper.useAnalyticsEventTracker("Quality section");
    const { t, i18n } = useTranslation();

    const getRegulationItem = (translationKey: string) => {
        return (
            <Trans i18nKey={translationKey}>
                {[
                    <span className='feedoil-bold'></span>,
                    ''
                ]}
            </Trans>
        )
    }

    const getCertificate = (language: string): string => {
        if(language === 'en')
            return 'GMP_cert_EN.pdf'
        else if (language === 'pl')
            return 'GMP_cert_PL.pdf'
        
        return 'GMP_cert_EN.pdf'
    }
    return (
        <Element name="jakosc" className='feedoil-section'>
            <h1>{t('SECTION_QUALITY.header')}</h1>
            <p className='feedoil-operate'>
                <Trans i18nKey='SECTION_QUALITY.veterinary_supervision'>
                    {[
                        <span className='feedoil-bold'></span>,
                        <a className='feedoil-link' rel="noreferrer" target="_blank" href={i18n.language === 'pl' ? 'https://www.wetgiw.gov.pl' : 'https://www.wetgiw.gov.pl/english'}></a>
                    ]}
                </Trans><br />
                <span className='feedoil-bold'>{t('SECTION_QUALITY.register_number')}</span> <a className='feedoil-link' href="./documents/PL_wet.pdf" rel="noreferrer" target="_blank" onClick={() => qualityEventTracker("Wet certificate link", "wet-certificate-click")}>{CONSTANTS.SECTION_QUALITY.veterinary_number}</a><br />
                <span className='feedoil-bold'>{t('SECTION_QUALITY.qs_number_label')}</span> {CONSTANTS.SECTION_QUALITY.qs_number} (<a className='feedoil-link' href='https://www.q-s.de' rel="noreferrer" target="_blank" onClick={() => qualityEventTracker("QS link", "qs-link-click")}>www.q-s.de</a>) <br />
                <span className='feedoil-bold'>{t('SECTION_QUALITY.gmp_number_label')}</span> {CONSTANTS.SECTION_QUALITY.gmp_number} (<a className='feedoil-link' href='https://www.gmpplus.org' rel="noreferrer" target="_blank">www.gmpplus.org</a>)
            </p>
            <div className='feedoil-quality'>

                <a className='feedoil-link' href='https://www.gmpplus.org' rel="noreferrer" target="_blank" onClick={() => qualityEventTracker("GMP logo", "gmp-logo-click")}>
                    <img className='gmp-logo' src='./assets/gmp-logo.png' alt='gmp-logo' />
                </a>
                <div className='gmp-text'>GMP+ B3<br />
                    {t('SECTION_QUALITY.gmp_trading')}<br /> {t('SECTION_QUALITY.gmp_blends')}<br /> {t('SECTION_QUALITY.gmp_feed_materials')}
                </div>
                <a className='feedoil-link' href={`./documents/${getCertificate(i18n.language)}`} rel="noreferrer" target="_blank" onClick={() => qualityEventTracker("GMP certificate", "gmp-certificate-click")}>
                    {isSafari ?
                        <img className='gmp-certificate' src={`./documents/${getCertificate(i18n.language)}`} alt='gmp-certificate' />
                        : (
                            <div style={{ position: "relative" }}>
                                <iframe
                                    className='gmp-certificate-embed'
                                    src={`./documents/${getCertificate(i18n.language)}#view=Fit&toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=0`}
                                />
                                <a href={`./documents/${getCertificate(i18n.language)}`} rel="noreferrer" target="_blank" onClick={() => qualityEventTracker("GMP certificate", "gmp-certificate-click")} style={{ height: "420px", width: "300px", position: "absolute", top: "2rem", left: "0", display: "inline-block", zIndex: "5" }}></a>
                            </div>
                        )
                    }
                </a>
            </div>
            <p className='feedoil-regulation'>
                <Trans i18nKey='SECTION_QUALITY.regulation_standards.operating'>
                    {[
                        '',
                        <span className='feedoil-bold'></span>,
                        '',
                        <span className='feedoil-bold'></span>,
                        ''
                    ]}
                </Trans>

                <ul>
                    <li>
                        {getRegulationItem('SECTION_QUALITY.regulation_standards.first')}
                    </li>
                    <li>
                        {getRegulationItem('SECTION_QUALITY.regulation_standards.second')}
                    </li>
                    <li>
                        {getRegulationItem('SECTION_QUALITY.regulation_standards.third')}
                    </li>
                    <li>
                        {getRegulationItem('SECTION_QUALITY.regulation_standards.fourth')}
                    </li>
                    <li>
                        {getRegulationItem('SECTION_QUALITY.regulation_standards.fifth')}
                    </li>
                    <li>
                        {getRegulationItem('SECTION_QUALITY.regulation_standards.sixth')}
                    </li>
                    <li>
                        {getRegulationItem('SECTION_QUALITY.regulation_standards.seventh')}
                    </li>
                    <li>
                        {getRegulationItem('SECTION_QUALITY.regulation_standards.eighth')}
                        {' '}(<a className='feedoil-link' href='https://www.gmpplus.org' rel="noreferrer" target="_blank" onClick={() => qualityEventTracker("GMP site", "gmp-site-click")}>www.gmpplus.org</a>)
                    </li>
                </ul>

            </p>

        </Element>
    )
}