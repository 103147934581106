import { Element } from 'react-scroll';
import "./PagesStyles.scss";
import SkillsGrid from '../components/skills/SkillsGrid';
import { useTranslation } from 'react-i18next';

export default function Company() {
    const { t } = useTranslation();
    return (
        <Element name="firma" className='feedoil-section'>
            <h1>{t('SECTION_COMPANY.about')}</h1>
            <p>
                {t('SECTION_COMPANY.welcome1')} <b>{t('SECTION_COMPANY.welcomeBold')}</b> {t('SECTION_COMPANY.welcome2')}
            </p>
            <p>
                {t('SECTION_COMPANY.offer')}
            </p>
            <SkillsGrid translation={t}/>
        </Element>
    )
}