import GridElement from "./GridElement";

interface ISkillsGrid {
    translation: any;
}

export default function SkillsGrid({translation}: ISkillsGrid) {
    return (
        <div className='skills-grid'>
            <GridElement
                headerText={translation('SECTION_COMPANY.skills.experience.title')}
                descriptionText={translation('SECTION_COMPANY.skills.experience.description')}
            />
            <GridElement
                headerText={translation('SECTION_COMPANY.skills.engagement.title')}
                descriptionText={translation('SECTION_COMPANY.skills.engagement.description')}
            />
            <GridElement
                headerText={translation('SECTION_COMPANY.skills.organization.title')}
                descriptionText={translation('SECTION_COMPANY.skills.organization.description')}
            />
            <GridElement
                headerText={translation('SECTION_COMPANY.skills.quality.title')}
                descriptionText={translation('SECTION_COMPANY.skills.quality.description')}
            />
        </div>
    )
}