import './App.scss';
import Navbar from './components/navigation/Navbar';
import Company from './pages/Company';
import Products from './pages/Products';
import Home from './pages/Home';
import FeedoilDivider from './components/helpers/Divider';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
import Quality from './pages/Quality';
import { useEffect } from 'react';
import AnalyticsHelper from './components/helpers/AnalyticsHelper';
import { Helmet, HelmetProvider } from "react-helmet-async";
import CONSTANTS from './Constants';
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

function App() {

  const { t, i18n } = useTranslation();

  const handleAcceptCookie = () => {
    AnalyticsHelper.initialize()
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
      AnalyticsHelper.monitorUrls()
    }
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{t('SITE_TITLE')}</title>
          <meta
            name="description"
            content={CONSTANTS.SITE_DESCRIPTION}
          />;
        </Helmet>
        <Navbar />

        <div className='container'>

          <Home />
          <div className='page-content'>
            <FeedoilDivider />
            <Company />
            <Products />
            <Quality />
            <Contact />
            <Footer />
          </div>
        </div>
      </HelmetProvider>

      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonText={t('COOKIE.accept')}
        declineButtonText={t('COOKIE.decline')}
        cookieName='feedoil-analytics-cookie'
        buttonClasses='cookie-accept'
        declineButtonClasses='cookie-decline'
      >
        {t('COOKIE.consent_text')}<br />
        <a className='feedoil-link' href='http://www.google.pl/intl/pl/analytics/privacyoverview.html'>http://www.google.pl/intl/pl/analytics/privacyoverview.html</a><br />
        <a className='feedoil-link' href='http://www.google.pl/intl/pl/policies/privacy/'>http://www.google.pl/intl/pl/policies/privacy/</a>
      </CookieConsent>
    </>

  );
}

export default App;
