import { Element } from 'react-scroll'
import CardCarousel from '../components/carousel/CardCarousel'
import AnalyticsHelper from '../components/helpers/AnalyticsHelper'
import { Trans, useTranslation } from 'react-i18next';

export default function Products() {
    const productEventTracker = AnalyticsHelper.useAnalyticsEventTracker("Product section");
    const { t } = useTranslation();

    const getProductList = () => {
        return [
            {
                title: t('SECTION_PRODUCTS.products.f55.title'),
                description: t('SECTION_PRODUCTS.products.f55.description'),
            },
            {
                title: t('SECTION_PRODUCTS.products.f20.title'),
                description: t('SECTION_PRODUCTS.products.f20.description'),
            },
            {
                title: t('SECTION_PRODUCTS.products.f30-5.title'),
                description: t('SECTION_PRODUCTS.products.f30-5.description'),
            },
            {
                title: t('SECTION_PRODUCTS.products.f30.title'),
                description: t('SECTION_PRODUCTS.products.f30.description'),
            }
        ]
    }

    return (
        <Element name="produkty" className='feedoil-section'>
            <h1>{t('SECTION_PRODUCTS.header')}</h1>
            <p>
                <Trans i18nKey='SECTION_PRODUCTS.about'>
                    {[
                        <b></b>,
                        ''
                    ]}
                </Trans>
            </p>

            <CardCarousel items={getProductList()} />
            <a className="feedoil-link" href="mailto:handel@feedoil.pl" onClick={() => productEventTracker("Contact mail", "trade-mail-click")}>{t('SECTION_PRODUCTS.ask_for_details')}</a>
            <p>
                <Trans i18nKey='SECTION_PRODUCTS.other_products'>
                    {[
                        <b></b>,
                        '',
                        <a className='feedoil-link' rel="noreferrer" target="_blank" href="mailto:handel@feedoil.pl" onClick={() => productEventTracker("Contact mail", "trade-mail-click")}></a>
                    ]}
                </Trans>
            </p>
            <p>
                <Trans i18nKey='SECTION_PRODUCTS.ontime_delivery'>
                    {[
                        '',
                        <b></b>,
                        ''
                    ]}
                </Trans>
            </p>
            <p>
                <Trans i18nKey='SECTION_PRODUCTS.transport'>
                    {[
                        '',
                        <b></b>,
                        ''
                    ]}
                </Trans>
                <a className='feedoil-link' rel="noreferrer" target="_blank" href='https://www.whs-logistics.com'>WHS Logistics</a>
            </p>

            <img className="feedoil-transport-img" src='./assets/transport.jpg' alt='transport' />
        </Element>
    )
}