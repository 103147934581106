import React, { useState } from 'react'

import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import CONSTANTS from '../../Constants';

interface ICompanyMap {
    mapHeight: string;
    mapWidth: string;
    translation: any;
}

const center = {
    lat: 53.472472,
    lng: 15.334238
};

export default function CompanyMap({translation}: ICompanyMap) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    })
    const OPTIONS = {
        minZoom: 8,
        maxZoom: 15,
        disableDefaultUI: true,
        zoomControl: true
    }
    const [map, setMap] = useState(null)

    const onLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <div className='feedoil-contact-map'>
            <GoogleMap
                center={center}
                zoom={14}
                options={OPTIONS}
                onLoad={onLoad}
                onUnmount={onUnmount}

            >
                <Marker
                    position={{ lat: CONSTANTS.SECTION_CONTACT.map_coordinates.x, lng: CONSTANTS.SECTION_CONTACT.map_coordinates.y }}
                >
                    <InfoWindow>
                        <div className="feedoil-info-window-content">
                            <img src="feedoil-logo.png" width="24px" height="24px" />
                            <b><p>{translation('SECTION_CONTACT.company_name')}</p></b>
                            <p>{translation('SECTION_CONTACT.company_street')}</p>
                            <p>{translation('SECTION_CONTACT.company_city')}</p>
                        </div>
                    </InfoWindow>
                </Marker>
            </GoogleMap>
        </div>
    ) : <></>
}