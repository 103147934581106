import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import AnalyticsHelper from '../components/helpers/AnalyticsHelper';
import CompanyMap from '../components/helpers/CompanyMap';

export default function Contact() {
    const contactEventTracker = AnalyticsHelper.useAnalyticsEventTracker("Contact section");
    const { t } = useTranslation();
    return (
        <Element name="kontakt" className='feedoil-section'>
            <h1>{t('SECTION_CONTACT.title')}</h1>
            <p>
                {t('SECTION_CONTACT.contact_text')}
            </p>
            <div className='company-address'>
                <h2>{t('SECTION_CONTACT.company_full_name')}</h2>
                <p>{t('SECTION_CONTACT.company_street')}</p>
                <p>{t('SECTION_CONTACT.company_city')}</p>
            </div>

            <div className='feedoil-contact-info'>
                <div className='company-contact'>
                    <div className='contact-element'>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <a className='feedoil-link' href="tel:+48785006285" onClick={() => contactEventTracker("Phone number", "phone-number-click")}>+48 785 006 285</a>
                    </div>
                    <div className='contact-element'>
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        <a className='feedoil-link' href="mailto:info@feedoil.pl" onClick={() => contactEventTracker("Info mail", "info-mail-click")}>info@feedoil.pl</a>
                    </div>
                    <div className='contact-element'>
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        <a className='feedoil-link' href="mailto:info@feedoil.pl" onClick={() => contactEventTracker("Contact mail", "trade-mail-click")}>handel@feedoil.pl</a>
                    </div>
                </div>
                <div className='company-info'>
                    <p><b>NIP:</b> {t('SECTION_CONTACT.company_nip')}</p>
                    <p><b>REGON:</b> {t('SECTION_CONTACT.company_regon')}</p>
                    <p><b>KRS:</b> {t('SECTION_CONTACT.company_krs')}</p>
                </div>
            </div>

            <CompanyMap mapWidth='600px' mapHeight='400px' translation={t} />
        </Element>
    )
}