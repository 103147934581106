interface INavItem {
    title: string;
    hashLink: string;
}

export const getNavigationItems = (language: string) => {
    if (language === 'pl') {
        return PLNavigationItems;
    } else {
        return ENNavigationItems;
    }
}

const PLNavigationItems: INavItem[] = [
    {
        title: "Firma",
        hashLink: "firma"
    },
    {
        title: "Produkty",
        hashLink: "produkty"
    },
    {
        title: "Jakość",
        hashLink: "jakosc"
    },
    {
        title: "Kontakt",
        hashLink: "kontakt"
    }
]

const ENNavigationItems: INavItem[] = [
    {
        title: "Company",
        hashLink: "firma"
    },
    {
        title: "Products",
        hashLink: "produkty"
    },
    {
        title: "Quality",
        hashLink: "jakosc"
    },
    {
        title: "Contact",
        hashLink: "kontakt"
    }
]