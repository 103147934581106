import { useState } from 'react';
import Card from './Cards';
import Paginator from './Paginator';
import { useSwipeable } from 'react-swipeable';

interface ICardCarouselProps {
    items: any[];
}

const CardCarousel = (props: ICardCarouselProps): JSX.Element => {

    const [index, setIndex] = useState(0);

    const slideLeft = (): void => {
        if (index > 0) {
            setIndex(index - 1);
        } else {
            setIndex(props.items.length-1)
        }
    }
    const slideRight = (): void => {
        if (index < props.items.length - 1) {
            setIndex(index + 1);
        } else {
            setIndex(0);
        }
    }

    const handlePageChange = (page: number) => {
        let n = page - index;
        setIndex(index + n);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => slideRight(),
        onSwipedRight: () => slideLeft()
    })

    return (
        <div className="feedoil-cardcarousel-container">
            <div className="feedoil-cardcarousel-btn" onClick={slideLeft}><i className="fa fa-chevron-left"></i></div>
            <div {...handlers} className="feedoil-cardcarousel-card-container">
                <Paginator
                    dataLength={props.items.length}
                    activeIndex={index}
                    handlePageChange={handlePageChange}
                />
                {props.items.map((person, n) => {
                    let position = n > index ? "feedoil-cardcarousel-nextCard" : n === index ?
                        "feedoil-cardcarousel-activeCard" : "feedoil-cardcarousel-prevCard";
                    return <Card {...person} cardStyle={position} />;
                })}
            </div>
            <div onClick={slideRight} className="feedoil-cardcarousel-btn"><i className="fa fa-chevron-right"></i></div>
        </div>
    )
}

export default CardCarousel