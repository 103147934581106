import './CardCarousel.scss';


export interface ICardProps{
    title: string;
    description : string;
    cardStyle?: string;
}

const Card = (props: ICardProps): JSX.Element => {
    return (
      <div className={`feedoil-cardcarousel-card ${props.cardStyle}`}>
        <h2>{props.title}</h2>
        <p>{props.description}</p>
      </div>
    );
  };
  
  export default Card;