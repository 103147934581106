export interface IPaginatorProps {
    dataLength: number;
    activeIndex: number;
    handlePageChange: (index: number) => void;
    }

const Paginator = (props: IPaginatorProps) => {
    let dots: any[] = [];
    let classes: string = "";
    for (let index = 0; index < props.dataLength; index++) {
      classes = props.activeIndex === index ? "feedoil-cardcarousel-dot active" : "feedoil-cardcarousel-dot";
      dots.push(
        <div
          key={index}
          className={classes}
          onClick={() => props.handlePageChange(index)}
        ></div>
      );
    }
    return (
      <div className="feedoil-cardcarousel-paginator">
        <div className="feedoil-cardcarousel-hr"></div>
        {dots.map((dot) => dot)}
      </div>
    );
  };
  
  export default Paginator;