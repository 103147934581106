import React, { useState, useEffect } from 'react';
import './Navbar.scss'
import * as Scroll from 'react-scroll';
import HomeNav from './Home';
import NavLinks from './Nodes';
import Hamburger from 'hamburger-react'
import LanguageComponent from '../helpers/LanguageComponent';
import { useTranslation } from 'react-i18next';

export default function Navbar() {
    const [isSticky, setIsSticky] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isHamburgerOpen, setHamburgerOpen] = useState(false);
    const { i18n } = useTranslation();

    let scroll = Scroll.animateScroll;

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 40)
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    useEffect(() => {
        hamburgerChangePageView(isHamburgerOpen);
    }, [isHamburgerOpen]);

    function hamburgerChangePageView(isHamburgerOpen: boolean) {
        if (isHamburgerOpen) {
            document.body.style.overflow = "hidden";
            document.querySelectorAll(".container")
                .forEach(element => {
                    const htmlElement = element as HTMLElement;
                    htmlElement.style.filter = "blur(5px)"
                    htmlElement.style.pointerEvents = "none"
                });
        }
        else {
            document.body.style.overflow = ""
            document.querySelectorAll(".container")
                .forEach(element => {
                    const htmlElement = element as HTMLElement;
                    htmlElement.style.filter = ""
                    htmlElement.style.pointerEvents = ""
                });
        }
    }

    return (
        <nav className={`feedoil-nav ${isSticky ? "sticky" : ""}`}>
            <HomeNav
                logo='feedoil-logo.png'
                logoAltText='Feedoil logo'
                title='Feedoil'
                scroll={scroll}
            />

            <div style={{ textAlign: "right", display: "flex" }}>
                <NavLinks
                    className={isHamburgerOpen ? "feedoil-nav-links hamburger-open" : "feedoil-nav-links"}
                    currentLanguage={i18n.language}
                    setHamburgerOpenHook={setHamburgerOpen}
                    isHamburgerOpen={isHamburgerOpen}
                />
                <LanguageComponent />
            </div>


            <div className='hamburger'>
                <Hamburger
                    toggled={isHamburgerOpen}
                    toggle={setHamburgerOpen}
                    color={isHamburgerOpen ? "#333" : "white"}
                    size={25}
                />
            </div>
            
        </nav>
    )
}