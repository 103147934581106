import * as Scroll from 'react-scroll';
import AnalyticsHelper from '../components/helpers/AnalyticsHelper';
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const footerEventTracker = AnalyticsHelper.useAnalyticsEventTracker("Footer section")

    const { t } = useTranslation();
    let scroll = Scroll.animateScroll;
    return (
        <div className="feedoil-section feedoil-footer">
            <p>&copy;<a className='feedoil-link home' onClick={() => scroll.scrollToTop()}>Feedoil Sp. z o.o.</a>{new Date().getFullYear()}</p>
            <p>{t('SECTION_FOOTER.implementation')} <a className='feedoil-link' href="mailto:piotr.janus@janus.biz" onClick={() => footerEventTracker("Contact mail Piotr", "contact-mail-piotr-click")}>Piotr Janus</a> & <a className='feedoil-link' href="mailto:jaksyska@gmail.com" onClick={() => footerEventTracker("Contact mail Jakub", "contact-mail-jakub-click")}>Jakub Syska</a></p>
        </div>
    )
}