interface RoundedIconProps {
    iconName: string;
    iconSize: string;
    circleRadius: string;
}

export default function RoundedIcon(props: RoundedIconProps) {
    return (
        <div className='feedoil-rounded-icon' style={{height: props.circleRadius, width: props.circleRadius, fontSize: props.iconSize}}>
            <i className={props.iconName}></i>
        </div>
    )
}